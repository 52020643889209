import { ReactNode, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Center, FormControl, Text } from '@chakra-ui/react'
import { Attachment, FormErrorMessage } from '@opengovsg/design-system-react'

import { fmtBytes } from '~/utils/humanReadable'

export interface AttachmentFieldProps {
  /**
   * Function to call when user submits files.
   */
  onSubmit: (files: File[]) => Promise<void>

  /**
   * Maximum file size in bytes
   */
  maxSizeInBytes?: number

  /**
   * List of comma separated file extensions to accept
   */
  accept?: string

  /**
   * Max filename length (including extension)
   */
  maxFileNameLen?: number

  /**
   * Regex to test filename against.
   */
  fileNameRegex?: RegExp

  /**
   * Error to display if filename is invalid.
   */
  fileNameRegexErrorMsg?: string
}

/**
 * Attachment field that automatically submits on drop
 */
export const AttachmentField = ({
  onSubmit,
  maxSizeInBytes,
  accept,
  maxFileNameLen,
  fileNameRegex = /^[\w\-()!. ]+\.\w+$/,
  fileNameRegexErrorMsg,
}: AttachmentFieldProps) => {
  const name = 'fileInput'
  const {
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    getValues,
    control,
    formState,
    formState: { errors, isValidating, submitCount },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      fileInput: [], // Defaulting the file input field to an empty array
    },
  })

  const isInvalid = !!errors?.[name]

  const handleOnSubmit = handleSubmit(
    async (values: Record<string, File[]>) => {
      const files = values[name]
      setValue(name, []) // Clear the input after submission
      await onSubmit(files)
    },
  )

  useEffect(() => {
    if (
      formState.isValid &&
      !isValidating &&
      !formState.isSubmitting &&
      getValues(name).length !== 0
    ) {
      void handleOnSubmit()
    }
  }, [formState, getValues, isValidating]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form>
      <FormControl isInvalid={isInvalid}>
        <Controller
          render={({ field: { onChange, ...rest } }) => (
            <Attachment
              multiple={true} // Enable multiple file uploads
              key={submitCount}
              {...rest}
              onChange={(files) => {
                clearErrors(name)
                onChange(files)
              }}
              onError={(message: string) => {
                setError(name, { message })
              }}
              accept={accept}
              isInvalid={isInvalid}
            />
          )}
          name={name}
          rules={{
            validate: {
              // Validate each file in the array
              validateFilename: (files: File[]) =>
                files.every((file) =>
                  file === undefined
                    ? true
                    : fileNameRegex.test(file.name) ||
                      (fileNameRegexErrorMsg ?? 'Invalid Filename'),
                ),
              validateFilenameLength: (files: File[]) =>
                files.every((file) =>
                  file === undefined || maxFileNameLen === undefined
                    ? true
                    : file.name.length < maxFileNameLen ||
                      `Filename too long (maximum ${maxFileNameLen} characters)`,
                ),
              validateFileSize: (files: File[]) =>
                files.every((file) =>
                  file === undefined || !maxSizeInBytes
                    ? true
                    : file.size <= maxSizeInBytes ||
                      `File size exceeds limit of ${fmtBytes(maxSizeInBytes)}`,
                ),
            },
          }}
          control={control}
        />
        <FormErrorMessage>
          <Center>{errors[name]?.message as ReactNode}</Center>
        </FormErrorMessage>
      </FormControl>
    </form>
  )
}
